<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="footer-section-wrapper">
        <div class="left">
          <div class="first-row">
            <ul class="left-list">
              <li class="left-list__item">{{ $t('Support') }}: {{ $parent.footerSupportEmail }}</li>
              <li class="left-list__item">{{ companyName }}</li>
            </ul>
          </div>
          <div class="second-row">
            {{ $parent.footerCopyright }}
          </div>
        </div>
        <div class="center">
            <router-link to="/">
              <img src="./../assets/footer-logo.png" alt="" class="img">
            </router-link>
        </div>
        <!-- <ul class="nav">
          <template v-for="(item, i) in $parent.nav">
            <li class="nav__item" :key="i">
              <router-link :to="item.link">
                {{ $t(item.title) }}
              </router-link>
            </li>
          </template>
</ul>
<ul class="nav">
  <template v-for="item in $parent.textPageList">
            <li class="nav__item" :key="item.id">
              <a @click="$parent.goToPage(item.id)">
                {{ $t(item.title) }}
              </a>
            </li>
          </template>
</ul> -->
        <div class="right">
          <ul class="payment-methods-list">
            <li class="payment-methods-list__item">
              <img src="./../assets/mastercard_icon.svg" alt="">
            </li>
            <li class="payment-methods-list__item">
              <img src="./../assets/visa_icon.svg" alt="">
            </li>
            <li class="payment-methods-list__item">
              <img src="./../assets/3ds.svg" alt="">
            </li>

            <!-- <li class="payment-methods-list__item">
              <img src="./../assets/methods/blik.svg" alt="">
            </li>
            <li class="payment-methods-list__item">
              <img src="./../assets/methods/eps.svg" alt="">
            </li> --> 
            <!-- <li class="payment-methods-list__item">
              <img src="./../assets/methods/klarnna.png" alt="">
            </li>
            <li class="payment-methods-list__item">
              <img src="./../assets/methods/przelewy24.png" alt="">
            </li>
            
            <li class="payment-methods-list__item">
              <img src="./../assets/methods/klarna.png" alt="">
            </li>
            <li class="payment-methods-list__item">
              <img src="./../assets/methods/mb.svg" alt="">
            </li>
            <li class="payment-methods-list__item">
              <img src="./../assets/methods/multiBanco.svg" alt="">
            </li>
            <li class="payment-methods-list__item">
              <img src="./../assets/methods/paysafecard.png" alt="">
            </li>--> 
            <li class="payment-methods-list__item apple-pay">
              <img src="./../assets/methods/apple-pay.svg" alt="">
            </li>
            <li class="payment-methods-list__item google-pay">
              <img src="./../assets/methods/google-pay.svg" alt="">
            </li>
          </ul>
          <ul class="site-navigation__list">
            <li v-for="page in pages" :key="page.id">
              <router-link :to="{ name: 'TextPage', params: { slug: page.slug } }">
                {{ $t(page.title) }}
              </router-link>
            </li>
            <li class="site-navigation__item"><router-link to="/">{{ $t('Home') }}</router-link></li>
            <li class="site-navigation__item"><router-link to="/products/all">{{ $t('Shop') }}</router-link></li>
            <li class="site-navigation__item"><router-link to="/products/new-arrivals">{{ $t('New skins')
                }}</router-link>
            </li>
            <li class="site-navigation__item"><router-link to='/products/sale'>{{ $t('Sale') }}</router-link></li>
          </ul>
        </div>

        <!-- <div class="social-links-wrapper">
          <div class="social-links">
            <a :href="link.link" v-for="link in $parent.socialLinks" class="link" v-bind:key="link.link">
              <img :src="link.img" class="link-img" />
            </a>
          </div>
        </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "Footer",
  props: [],
  data: function () {
    return {
      footerRequisites: '',
    };
  },
  mounted() {
  },
  computed: {
    ...mapState('staticPages', ['pages']),
    companyName() {
      if (this.$parent.footerRequisites && this.$parent.footerRequisites.includes(',')) {
        return this.$parent.footerRequisites.split(',')[0].trim();
      }
      return '';
    },
    companyNumber() {
      if (this.$parent.footerRequisites && this.$parent.footerRequisites.includes(',')) {
        return this.$parent.footerRequisites.split(',')[1].replace('Company Number: ', '').trim();
      }
      return '';
    }
  },
  methods: {
  }
};
</script>
